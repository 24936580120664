import React from "react";

export const Logo = (props) => (
  <svg width={88} height={88} fill="none" viewBox="0 0 88 88" {...props}>
    <path
      fill="#fff"
      d="M43.516.362C19.482.362 0 19.845 0 43.879 0 67.91 19.483 87.394 43.516 87.394c24.033 0 43.516-19.484 43.516-43.516C87.032 19.845 67.55.361 43.516.361zm1.069 5.804c20.348.568 36.67 17.229 36.67 37.713 0 .093-.005.184-.007.278C61.96 41.242 46.833 25.666 44.585 6.166z"
    />
    <path
      fill="#1E2F5D"
      d="M23.525 56.644h2.236c0-2.431-1.417-3.874-3.393-3.874-2.314 0-3.783 1.846-3.783 4.966 0 2.756 1.378 4.394 3.614 4.394 1.079 0 1.989-.338 2.938-.975v-1.95c-.91.689-1.781.936-2.561.949-1.391.026-1.755-.715-1.755-2.704 0-1.976.364-2.704 1.443-2.704.897 0 1.261.507 1.261 1.898zm3.895 3.068v2.041c.923.26 1.976.377 2.704.377 2.392 0 3.653-1.04 3.653-2.834 0-3.484-4.368-1.963-4.368-3.757 0-.39.208-.793 1.248-.793a6.93 6.93 0 012.6.533V53.29c-.767-.325-1.78-.52-2.678-.52-2.223 0-3.406 1.092-3.406 2.938 0 3.484 4.368 2.158 4.368 3.718 0 .377-.26.728-1.417.728a8.714 8.714 0 01-2.704-.442zm8.316 0v2.041c.923.26 1.976.377 2.704.377 2.392 0 3.653-1.04 3.653-2.834 0-3.484-4.368-1.963-4.368-3.757 0-.39.208-.793 1.248-.793a6.93 6.93 0 012.6.533V53.29c-.767-.325-1.781-.52-2.678-.52-2.223 0-3.406 1.092-3.406 2.938 0 3.484 4.368 2.158 4.368 3.718 0 .377-.26.728-1.417.728a8.714 8.714 0 01-2.704-.442zm12.826-1.014c1.34-.897 2.041-1.885 2.041-3.081 0-1.599-1.274-2.847-3.575-2.847-1.079 0-2.184.26-3.055.715v1.95c.78-.403 1.664-.689 2.652-.689 1.092 0 1.742.351 1.742 1.183 0 .468-.208.845-.962 1.391l-3.614 2.613V62h6.812v-1.872h-4.186l2.145-1.43zm4.52-5.798v1.872h3.289v3.081c0 1.625-1.222 2.34-3.94 2.34v1.872c3.979 0 6.072-1.495 6.072-4.446V52.9h-5.421zm7.6 6.812v2.041c.923.26 1.976.377 2.704.377 2.392 0 3.653-1.04 3.653-2.834 0-3.484-4.368-1.963-4.368-3.757 0-.39.208-.793 1.248-.793a6.93 6.93 0 012.6.533V53.29c-.767-.325-1.78-.52-2.678-.52-2.223 0-3.406 1.092-3.406 2.938 0 3.484 4.368 2.158 4.368 3.718 0 .377-.26.728-1.417.728a8.714 8.714 0 01-2.704-.442z"
    />
  </svg>
);
